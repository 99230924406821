export default function Hero() {
  return (
    <section id="home" className="hero hero-bg">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title is-size-1">Alain Brouillaud</h1>
          <h2 className="subtitle is-size-4">
            <strong>Professional Tennis Coach</strong>
          </h2>
        </div>
      </div>
    </section>
  );
}

import iconWhatsApp from "../assets/whatsapp.png";
import iconEmail from "../assets/mail.png";
import iconMap from "../assets/map.png";

import "./Home.css";
import About from "./About";
import Videos from "./Videos";
import Reviews from "./Reviews";
import Hero from "./Hero";
import Navigation from "./Navigation";
import Courses from "./Courses";
import {
  Link,
  Box,
  Divider,
  Flex,
  Text,
  VStack,
  Center,
  Spacer,
  Heading,
  HStack,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

function FooterElement({ icon, content }: { icon: string; content: string }) {
  return (
    <HStack p={2}>
      <span className="icon-text">
        <span className="icon">
          <img src={icon} alt="" />
        </span>
        <span>&nbsp;&nbsp;{content}</span>
      </span>
    </HStack>
  );
}

function sectionForFooter() {
  return (
    <section id="contact" className="hero has-background-light">
      <div className="container">
        <Flex gap={2} p={4} wrap={"wrap"} mt={8}>
          <VStack align={"flex-start"} w={{ base: "full", md: "350px" }}>
            <Heading size={"lg"}>Contact</Heading>
            <FooterElement icon={iconWhatsApp} content="040 733 9282" />
            <FooterElement icon={iconEmail} content="alainbrouillaud@mac.com" />
            <FooterElement
              icon={iconMap}
              content="Talin Tenniskeskus, Helsinki"
            />
          </VStack>
          <Spacer />
          <VStack align={"flex-start"} p={2} w={{ base: "full", md: "350px" }}>
            <Heading size={"lg"}>Social Media</Heading>
            <Link
              href="https://www.youtube.com/playlist?list=PLxFP6IGkuwecO6iS23mdYzxpbUvPUxbmj"
              isExternal
            >
              Facebook <ExternalLinkIcon mx="2px" />
            </Link>
            <Link
              href="https://www.youtube.com/playlist?list=PLxFP6IGkuwecO6iS23mdYzxpbUvPUxbmj"
              isExternal
            >
              Youtube <ExternalLinkIcon mx="2px" />
            </Link>
          </VStack>
          <Spacer />
          <VStack align={"flex-start"} p={2} w={{ base: "full", md: "350px" }}>
            <Heading size={"lg"}>Links</Heading>
            <Link href="/terms-of-service.html" isExternal>
              Terms of Service <ExternalLinkIcon mx="2px" />
            </Link>
            <Link href="https://www.sendowl.com/" isExternal>
              Powered by SendOwl <ExternalLinkIcon mx="2px" />
            </Link>
            <Link
              href="https://www.tennishedman.fi/en/tennis-hedman/#valmentajamme"
              isExternal
            >
              Tennis Hedman
              <ExternalLinkIcon mx="2px" />
            </Link>
          </VStack>
        </Flex>
        <Box alignContent={"center"} alignItems={"center"} pb={8}>
          <Center>
            <VStack w={"full"} px={4}>
              <Divider
                orientation="horizontal"
                height={"0.5px"}
                bg={"blackAlpha.400"}
              />
              <Text align={"center"}>
                www.spindoctortennis.com | Alain Brouillaud | Professional
                Tennis Coach
              </Text>
              <Text fontSize={"sm"} align={"center"}>
                © 2024 All rights reserved. | Site design by Maninder Singh
              </Text>
            </VStack>
          </Center>
        </Box>
      </div>
    </section>
  );
}

export default function Home() {
  return (
    <div>
      <Navigation />
      <Hero />
      <About />
      <Courses />
      <Videos />
      <Reviews />
      {sectionForFooter()}
    </div>
  );
}

/**
 * <div className="column">
              <div className="media has-centered">
                <figure className="image is-128x128">
                  <img src={alainPortrait} alt="" />
                </figure>
              </div>
            </div>
  <Stack paddingTop={16} w={"full"}>
      <Heading py={8}>Tennis Video Gallery</Heading>
      <Flex wrap={"wrap"} gap={4}>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/1VpVc9vqkAk"
          title="Learning The “Circular Swing Pattern” for the Spin Serve"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        />
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/ZSzWKlj34M0"
          title="The Flat Serve Unlocked"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        />
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/-YbhgJh1zGE"
          title="Serve Tips with Alain 🎾: The Ball Toss"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        />
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/Pc3aoTEWV8w"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        />
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/dj-8y8WJd_s"
          title="First Serve aka The Flat Serve"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        />
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/yjZwXWEXGws"
          title="Second Serve Demonstration"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        />
      </Flex>

            */

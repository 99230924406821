type Review = {
  content: string;
  reviewBy: string;
  reviewAt: string;
};

const reviews: Review[] = [
  {
    content:
      "Coach Alain is an amazing tennis coach who knows his stuff and loves what he do. He is great at helping beginners get the basics and pushing more experienced players to up their game. What sets him apart is how he pay attention to the details, giving helpful feedback that really speeds up your progress. It's not just about skills – he also help you build a positive mindset, boosting your confidence. Off the court, he is professional, on time, and easy to communicate with. Whether you're just starting or looking to improve, Coach Alain is the go-to person for a top-notch tennis coaching experience. I'd give him a solid 5/5 – highly recommend!",
    reviewBy: "Maninder",
    reviewAt: "Oct 2024",
  },
  {
    content:
      "Alain is hands down the best tennis coach I have worked with so far. I have seen the greatest development in my skills within the few years I have been coached by him. His style of teaching is very calm and focuses on improving the technique of his clients. He is able to give very illustrative examples of how a certain stroke should be executed. His training does not focus on drills that are aerobically demanding but rather on things that truly matter from the perspective of a tennis player. Not only is he a great coach, but I also view him as a mentor who has shared valuable life lessons throughout the years I have been coached by him.",
    reviewBy: "Lassi",
    reviewAt: "Nov 2023",
  },
  {
    content:
      "Coach Alain, your coaching is truly transformative. Your passion for tennis is contagious, and your ability to simplify complex techniques has accelerated my progress. Your personalized attention and positive approach boost my confidence. Thank you for being not just a coach but a guiding force on and off the court.",
    reviewBy: "Giuseppe",
    reviewAt: "Jan 2024",
  },
];

export default function Reviews() {
  return (
    <section id="reviews" className="section">
      <div className="container">
        <h1 className="title">Reviews</h1>
        <div className="columns">
          {reviews.map((review) => {
            return (
              <div className="column">
                <div className="notification">
                  <p is-size-5>{review.content}</p>
                  <br />
                  <p className="subtitle">
                    by {review.reviewBy} - <small>{review.reviewAt}</small>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

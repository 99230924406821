export default function About() {
  return (
    <section id="coach" className="section padding-v-zero">
      <div className="container notification">
        <div className="columns">
          <div className="column">
            <div className="media">
              <div className="media-content">
                <div className="content is-size-4">
                  <p>
                    H​ello, I'm Alain. Thank you for visiting my website. My
                    teaching style focuses on technique. Good technique will
                    make playing tennis easier and more efficient. I attempt to
                    maximize your style of play and give your game the overall{" "}
                    <strong>balance</strong> it needs to compete. Every lesson
                    is designed with your needs in mind with an emphasis on the
                    foundations, technique, body mechanics and game strategy. I
                    have been a certified instructor with Professional Tennis
                    Registry (PTR) since 2000.
                  </p>
                  <p>
                    I have been teaching tennis since 1980. My coaching style
                    has evolved quite a bit since them. I am always open to new
                    information because tennis is constantly changing. I often
                    do presentations for Tennis Hedman coaches on techniques,
                    and strategies on how to teach tennis more effectively.
                    Check out my{" "}
                    <a href="https://www.youtube.com/playlist?list=PLxFP6IGkuwecO6iS23mdYzxpbUvPUxbmj">
                      <strong>YouTube channel</strong>
                    </a>{" "}
                    for bite-sized lessons and tennis advice. Don't forget to
                    subscribe!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
